import { useState } from "react"

export default function UserDelete() {
    const [selectedReason, setSelectedReason] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    document.addEventListener('keydown', function (ev) {
        if (ev.key === "Escape") {
            setShowDeleteModal(false);
        }
    })
    const handleModalWrapperClick = (e) => {
        if (e.target === document.getElementsByClassName("modal-wrapper")[0]) {
            setShowDeleteModal(false);
        }
    }
    const deletionReasons = [
        "Details Change",
        "I hate This App",
        "Not Interested",
        "Prefer not to disclose",
        "Other",
    ]
    return (
        <>
            <section className="user-delete-form-wrapper">
                <form className="user-delete-form">
                    <h1>Request For Account Deletion</h1>
                    <div className="input-wrapper">
                        <label htmlFor="username">Username<span>*</span></label>
                        <input type="text" name="username" id="username" className="input" placeholder="Your username" />
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="username">Reason <small>(Why are you requesting to delete your account?)</small></label>
                        <div name="reason" className="reasons">
                            {selectedReason !== "Other" && (
                                <input hidden value={selectedReason} />
                            )}
                            {deletionReasons.map((reason, key) => (
                                <div className={`reason ${selectedReason === reason ? "selected" : ""}`} key={key} onClick={() => setSelectedReason(reason)}>
                                    <span className="circle"></span>
                                    <div>{reason}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {selectedReason === "Other" && (
                        <div className="input-wrapper">
                            <label htmlFor="username">Specify Reason<small>(Optional)</small></label>
                            <input type="text" name="reason" id="reason" className="input" placeholder="" />
                        </div>
                    )}
                    <button type="button" className="btn" onClick={() => setShowDeleteModal(true)}>Submit</button>
                </form>
            </section>

            {showDeleteModal && (
                <div className="modal-wrapper" onClick={handleModalWrapperClick}>
                    <div className="modal">
                        <div className="modal-header">
                            Delete Your Account?
                            <button onClick={() => setShowDeleteModal(false)}>
                                <i className="fas fa-xmark"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete your account? This action is not reversible!!
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-cancel" onClick={() => setShowDeleteModal(false)}>
                                Cancel
                            </button>
                            <button className="btn">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
